import { FC, useContext } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { AuthService, RegisterRequest } from "../../services/auth.services";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context";

const Register: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get("redirectUrl");
  const { setUser } = useContext(AuthContext);

  const register = async (values: RegisterRequest) => {
    try {
      const { data } = await AuthService.register(values);
      localStorage.setItem("brand_access_token", data.accessToken);
      localStorage.setItem("brand_refresh_token", data.refreshToken);
      const resp = await AuthService.getMe();
      setUser(resp.data);
      if (redirectUrl?.length) {
        navigate(`/${redirectUrl}`);
      } else {
        navigate("/");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          `Request failed with status code ${error?.response?.status}`,
        { theme: "dark" }
      );
    } finally {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
    },
    onSubmit: (values) => {
      register(values);
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Emial is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .required("Please retype your password.")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const changePhone = (key: "phoneNumber", value: string) => {
    formik?.setFieldValue(key, value);
  };

  return (
    <div className="flex-1 h-screen bg-cod-gray">
      <div className="flex-1 h-full flex flex-row justify-center items-center">
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <div className="flex flex-col">
            <label htmlFor="email" className="text-silver-chalice">
              Email
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="example@google.com"
              value={formik?.values.email}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.email.toString()}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col mt-4">
            <label htmlFor="password" className="text-silver-chalice">
              Password
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="example"
              value={formik?.values.password}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.password && formik.errors.password ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.password.toString()}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col mt-4">
            <label htmlFor="confirmPassword" className="text-silver-chalice">
              Confirm Password
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="example"
              value={formik?.values.confirmPassword}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.confirmPassword.toString()}
              </span>
            ) : null}
          </div>

          <div className="flex flex-col mt-4">
            <label htmlFor="firstName" className="text-silver-chalice">
              First Name
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="George"
              value={formik?.values.firstName}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.firstName.toString()}
              </span>
            ) : null}
          </div>

          <div className="flex flex-col mt-4">
            <label htmlFor="lastName" className="text-silver-chalice">
              Last Name
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              placeholder="Cojoc"
              value={formik?.values.lastName}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.lastName.toString()}
              </span>
            ) : null}
          </div>

          <div className="flex flex-col mt-4">
            <label htmlFor="companyName" className="text-silver-chalice">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              placeholder="Brand"
              value={formik?.values.companyName}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100 placeholder-black::placeholder"
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.companyName.toString()}
              </span>
            ) : null}
          </div>

          <div className="flex flex-col mt-4">
            <label htmlFor="phoneNumber" className="text-silver-chalice">
              Phone Number
            </label>
            <PhoneInput
              containerClass={"min-w-[300px] bg-cod-gray outline-none mt-2"}
              inputClass="border-0 bg-cod-gray"
              placeholder={"+47122232323"}
              value={formik?.values.phoneNumber}
              onChange={(phone) => changePhone("phoneNumber", phone)}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.phoneNumber.toString()}
              </span>
            ) : null}
          </div>
          <button
            type="submit"
            className={`text-white text-base font-bold rounded-2xl p-2 mt-5 bg-hero-pattern`}
          >
            Register
          </button>
          <button
            className="mt-4 text-silver-chalice text-sm leading-6 font-normal"
            onClick={() =>
              navigate(
                redirectUrl ? `/login?redirectUrl=${redirectUrl}` : "/login"
              )
            }
          >
            or Log in
          </button>
        </form>
      </div>
      <Tooltip id="tooltip" className="max-w-[400px]" />
    </div>
  );
};

export default Register;
