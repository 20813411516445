import { FC } from "react";
import { useNavigate } from "react-router-dom";

const Home: FC = () => {
  const navigate = useNavigate();

  const goTo = (route: string) => {
    navigate(route);
  };

  return (
    <div className="p-5 h-screen flex-1 flex flex-row justify-center items-center bg-cod-gray">
      <div className="flex flex-col items-start">
        <button
          className="mt-2 p-1 text-silver-chalice text-sm leading-6 font-bold w-full text-left hover:text-white-100"
          onClick={() => goTo("/search")}
        >
          <p>Companies screening</p>
        </button>
        <button
          className="mt-2 p-1 text-silver-chalice text-sm leading-6 font-bold w-full text-left hover:text-white-100"
          onClick={() => goTo("/investment-banker")}
        >
          <p>Investment banker</p>
        </button>
        <button
          className="mt-2 p-1 text-silver-chalice text-sm leading-6 font-bold w-full text-left hover:text-white-100"
          disabled
        >
          <p>One pager</p>
        </button>
        {/* <button
          className="mt-2 p-1 text-gray-80 border-b border-solid border-gray-30 w-full text-left"
          disabled
        >
          <p>Success rate</p>
        </button>
        <div>
          <p className="mt-2 text-gray-90">Valuation</p>
          <button
            className="p-1 text-gray-80 ml-5 border-b border-solid border-gray-30 w-full text-left"
            disabled
          >
            <p>- Comparables</p>
          </button>
        </div>
        <button
          className="mt-2 p-1 text-gray-80 border-b border-solid border-gray-30 w-full text-left"
          disabled
        >
          <p>Financial Structure</p>
        </button> */}
      </div>
    </div>
  );
};

export default Home;
