import Api from "../axios/api";
export interface ResponseCountry {
  value: string;
  label: string;
}

export interface GetIndustiresResponse {
  data: ResponseCountry[];
}

export interface PaginationQuery {
  limit?: number;
  offset?: number;
  sortDirection?: string;
}

export class IndustiresService {
  static getAll(params: PaginationQuery): Promise<GetIndustiresResponse> {
    return Api.get(`industries`, {
      params,
    });
  }
}
