import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AuthContext } from "./context";
import { AuthProviderProps } from "./context.types";
import Api from "../axios/api";
import { AuthService, User } from "../services/auth.services";

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const getUser = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const user = await AuthService.getMe();
      setUser(user.data);
      Api.defaults.headers["Authorization"] = `Bearer ${token}`;
    } catch (e) {
      logout();
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const existToken = localStorage.getItem("brand_access_token");
    if (existToken) {
      getUser(existToken);
    } else {
      setLoading(false);
    }
  }, [getUser]);

  const logout = useCallback((redirectUrl?: string) => {
    localStorage.removeItem("brand_access_token");
    localStorage.removeItem("brand_refresh_token");
    let redirectPath = "";
    if (redirectUrl) {
      redirectPath = `?redirectUrl=${redirectUrl}`;
    }
    window.location.replace(`/login${redirectPath}`);
  }, []);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      user,
      setUser,
      logout,
    }),
    [loading, logout, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export default GlobalProvider;
