import Modal from "react-modal";

interface EditModalI {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  setName: (name: string) => void;
  name: string;
}

const EditModal = ({
  isOpen,
  onClose,
  onSubmit,
  name,
  setName,
}: EditModalI) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal-inside outline-none"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col p-5 h-full min-w-[180px]">
        <div className="flex flex-col justify-center">
          <p className="text-silver-chalice">Change name</p>
          <input
            id="name"
            type="text"
            value={name}
            className="border w-80 mt-1 text-white bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-row gap-2 mt-4 w-full justify-between">
          <button
            className="bg-transparent border-none text-white"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="bg-transparent border-none text-white"
            onClick={onSubmit}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
