import Modal from "react-modal";
import { FaWindowClose } from "react-icons/fa";
import { useEffect, useMemo, useState } from "react";
import {
  CompaniesListService,
  CompanyListDto,
} from "../../services/companies-list.service";
import classNames from "classnames";

interface CreateCompanyListI {
  isOpen: boolean;
  showInput?: boolean;
  onClose: () => void;
  companyIds: string[];
  editMode?: boolean;
  openList: (id: string) => void;
}

const CreateCompanyList = ({
  isOpen,
  onClose,
  showInput = true,
  editMode = true,
  companyIds,
  openList,
}: CreateCompanyListI) => {
  const [name, setName] = useState<string>("");
  const [selected, setSelected] = useState<string | undefined>();
  const [lists, setLists] = useState<CompanyListDto[]>([]);

  const createList = async () => {
    try {
      if (selected) {
        await CompaniesListService.addCompanies(selected, companyIds);
        onClose();
      } else {
        const { data } = await CompaniesListService.create(name);
        await CompaniesListService.addCompanies(data.id, companyIds);
        onClose();
      }
      setName("");
    } catch (e) {
      console.log(e);
    }
  };

  const getLists = async () => {
    try {
      const { data } = await CompaniesListService.getAll();
      setName("");
      setLists(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLists();
  }, []);

  const disabledSave = useMemo(
    () => !name?.length && !selected,
    [name?.length, selected]
  );

  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal-inside outline-none min-w-[400px]"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col p-5 h-full">
        <div className="flex flex-row justify-end mb-3">
          <button onClick={onClose}>
            <FaWindowClose fill="#ffffff" stroke="#0A0A0A" />
          </button>
        </div>

        <div className="flex flex-col max-w-3xl overflow-y-auto h-full">
          {showInput ? (
            <div className="flex flex-col">
              <label htmlFor="name" className="text-silver-chalice">
                Name of List
              </label>
              <input
                id="name"
                type="text"
                value={name}
                className="border w-80 mt-1 text-white bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          ) : null}
          <div
            className={classNames("flex flex-col pb-3", { "mt-4": editMode })}
          >
            {lists?.length ? (
              <p className="m-0 mb-1 text-white">Lists</p>
            ) : null}
            {lists?.length ? (
              <div className="flex flex-col">
                {lists.map((l, index) => {
                  return (
                    <div key={l.id} className="flex flex-row mb-1">
                      {editMode ? (
                        <label className="checkbox_block">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setSelected(selected === l.id ? undefined : l?.id)
                            }
                            checked={l.id === selected}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ) : (
                        <p className="m-0 text-gray-50">{index + 1}.</p>
                      )}
                      <button
                        className={classNames(
                          "flex m-0 p-0 bg-transparent outline-none",
                          {
                            "cursor-pointer": !editMode,
                          }
                        )}
                        disabled={editMode}
                        onClick={() => openList(l.id)}
                      >
                        <p className="m-0 ml-2 text-silver-chalice">{l.name}</p>
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-row justify-center text-silver-chalice mt-3">
                You don't have lists
              </div>
            )}
          </div>
        </div>
        {editMode ? (
          <div className="mt-4 flex flex-row justify-end">
            <button
              onClick={createList}
              className={`bg-hero-pattern text-base font-bold rounded-2xl p-2 mt-5 w-28 text-white ${
                disabledSave ? "opacity-50" : ""
              }`}
              disabled={disabledSave}
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default CreateCompanyList;
