import Api from "../axios/api";

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  phoneNumber?: string;
  paid: boolean;
}

interface PostAuthResponse {
  data: AuthTokens;
}

export interface AuthRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  phoneNumber?: string;
}

interface GetMeResponse {
  data: User;
}

export class AuthService {
  static login(data: AuthRequest): Promise<PostAuthResponse> {
    return Api.post(`/auth/login`, data);
  }

  static register(data: RegisterRequest): Promise<PostAuthResponse> {
    return Api.post(`/auth/register`, data);
  }

  static getMe(): Promise<GetMeResponse> {
    return Api.get(`auth/me`);
  }
}
