import { FC, useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaLink,
  FaTwitter,
  FaPlus,
  FaFilter,
} from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { RiListSettingsFill } from "react-icons/ri";

import {
  CompaniesService,
  CompanyDto,
  FiltersCompanies,
} from "../../services/companies.service";
import Screening from "../../components/Screening";
import Table from "rc-table";
import classNames from "classnames";
import ViewCompany from "../../components/ViewCompany";
import CreateCompanyList from "../../components/CreateCompanyList";
import CompaniesList from "../../components/CompaniesList";
import { SpinnerCircularFixed } from "spinners-react";

const LIMIT = 10;
const Companies: FC = () => {
  const [data, setData] = useState<CompanyDto[]>([]);
  const [count, setCount] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string | undefined>("name");
  const [sortDirection, setSortDirection] = useState<string | undefined>("ASC");
  const [showTable, setShowTable] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersCompanies | undefined>();
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
  const [showCompanyListModal, setShowCompanyListModal] =
    useState<boolean>(false);
  const [showCompaniesListModal, setShowCompaniesListModal] =
    useState<boolean>(false);
  const [selectedCompany, setCompany] = useState<CompanyDto>();
  const [selectedList, setSelectedList] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCompanies = useCallback(async () => {
    try {
      setLoading(true);
      if (filters) {
        if (filters.searchAi?.length) {
          const { data } = await CompaniesService.search(filters.searchAi);
          setData(data?.items || []);
          setCount(data.count);
          setShowTable(true);
        } else {
          const { data } = await CompaniesService.getAll({
            ...filters,
            limit: LIMIT,
            offset: skip,
            sortBy,
            sortDirection,
          });
          setData(data?.items || []);
          setCount(data.count);
          setShowTable(true);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [filters, skip, sortBy, sortDirection]);

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, skip, sortBy, sortDirection]);

  const handlePageClick = (event: any) => {
    setSkip(event.selected * LIMIT);
  };

  const selectedItem = (id: string) => {
    let newItems = [];
    const exist = selected.some((c) => c === id);
    if (exist) {
      newItems = selected.filter((c) => c !== id);
    } else {
      newItems = [...selected, id];
    }
    setSelected(newItems);
  };

  const selectedAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      const newItems = data.map((r) => r.id);
      setSelected(newItems);
    }
  };

  const hideFilters = () => {
    setShowTable(false);
    setFilters(undefined);
    setSortBy("name");
    setSortDirection("ASC");
  };

  const applyFilters = (filters: FiltersCompanies) => {
    setSkip(0);
    setFilters(filters);
  };
  //   onClick: () => {
  //     let newDirection =
  //       sortBy === column && sortDirection === "ASC" ? "DESC" : undefined;
  //     setSortDirection(() => newDirection);
  //     if (sortBy !== column) {
  //       setSortBy(column);
  //       setSortDirection(newDirection);
  //     }
  //   },
  // });

  const openCompany = (value: CompanyDto) => {
    setCompany(value);
    setShowCompanyModal(true);
  };

  const addToList = (id: string) => {
    setSelected([id]);
    setShowCompanyListModal(true);
  };

  const columns = () => [
    {
      title: "Company",
      dataIndex: "",
      className: "text-left",
      fixed: true,
      key: "name",
      render(value: CompanyDto) {
        return (
          <div className="px-3 py-4 text-gray-80 border-0 border-r border-solid border-gray-30 shadow-name max-w-md bg-nero h-full">
            <div className="flex flex-row flex-1">
              <div className="flex flex-row items-center">
                <label className="checkbox_block">
                  <input
                    type="checkbox"
                    onChange={() => selectedItem(value?.id)}
                    checked={selected.includes(value?.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div
                className="h-10 w-10 shadow min-w-[40px] mt-1"
                onClick={() => openCompany(value)}
              >
                {value?.logo ? (
                  <img
                    src={value?.logo}
                    alt={value?.name}
                    className="w-full h-full object-contain"
                  />
                ) : value?.website ? (
                  <img
                    src={`https://logo.clearbit.com/${value?.website}`}
                    alt={value?.name}
                    className="w-full h-full object-contain"
                  />
                ) : null}
              </div>
              <div
                className="flex flex-col ml-3"
                onClick={() => openCompany(value)}
              >
                <p className="m-0 text-left min-w-[300px] text-silver-chalice">{value?.name}</p>
                <div className="mt-2 flex flex-row">
                  {value?.website ? (
                    <div>
                      <a href={value?.website} target="_blank" rel="noreferrer">
                        <FaLink />
                      </a>
                    </div>
                  ) : null}
                  {value?.social?.linkedinUrl ? (
                    <div className="ml-2">
                      <a
                        href={value?.social?.linkedinUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn />
                      </a>
                    </div>
                  ) : null}
                  {value?.social?.facebookUrl ? (
                    <div className="ml-2">
                      <a
                        href={value?.social?.facebookUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookF />
                      </a>
                    </div>
                  ) : null}
                  {value?.social?.twitterUrl ? (
                    <div className="ml-2">
                      <a
                        href={value?.social?.twitterUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      className: "text-left",
      key: "actions",
      render(value: CompanyDto) {
        return (
          <div className="px-3 py-4 truncate text-gray-80 flex flex-row items-center justify-center h-full">
            <button
              className="px-4 py-3 flex flex-row justify-center items-center text-white bg-mine-shaft rounded-2xl text-base font-bold w-28"
              onClick={() => addToList(value.id)}
            >
              <FaPlus />
              <p className="ml-2">Save</p>
            </button>
          </div>
        );
      },
    },
    {
      title: "Employees",
      dataIndex: "",
      className: "text-left",
      key: "employees",
      render(value: CompanyDto) {
        return (
          <div
            className=" px-3 py-4 truncate text-silver-chalice flex flex-row items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            {value?.employeesCount || value?.employees_count}
          </div>
        );
      },
    },
    {
      title: "Industry",
      dataIndex: "",
      className: "text-left",
      key: "industry",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 truncate text-silver-chalice flex flex-row items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            {value?.industry}
          </div>
        );
      },
    },
    {
      title: "Revenue",
      dataIndex: "",
      className: "text-left",
      key: "revenue",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 text-silver-chalice flex flex-row items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            <p>{value?.annualRevenue || value?.annual_revenue}</p>
          </div>
        );
      },
    },
    {
      title: "Short description",
      dataIndex: "",
      className: "text-left",
      key: "revenue",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 text-silver-chalice flex flex-row items-center max-w-lg justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            <p
              data-tooltip-id="tooltip"
              data-tooltip-content={
                value?.shortDescription || value?.short_description
              }
              className="overflow-ellipsis min-w-[300px] text-xs"
            >
              {value?.shortDescription || value?.short_description}
            </p>
          </div>
        );
      },
    },
    {
      title: "Keywords",
      dataIndex: "",
      className: "text-left",
      key: "revenue",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 text-silver-chalice flex flex-row max-w-xs items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            <p
              data-tooltip-id="tooltip"
              data-tooltip-content={value?.keywords}
              className="truncate"
            >
              {value?.keywords}
            </p>
          </div>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "",
      className: "text-left",
      key: "revenue",
      render(value: CompanyDto) {
        const address = value?.city
          ? `${value?.city}, ${value?.country}`
          : value?.address;
        return (
          <div
            className="px-3 py-4 text-silver-chalice flex flex-row items-center max-w-xs justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            <p data-tooltip-id="tooltip">{address}</p>
          </div>
        );
      },
    },
    {
      title: "Ownership Type",
      dataIndex: "",
      className: "text-left",
      key: "ownershipType",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 truncate text-silver-chalice flex flex-row items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            {value?.ownershipType || value?.ownership_type}
          </div>
        );
      },
    },
    {
      title: "Founded Year",
      dataIndex: "",
      className: "text-left",
      key: "foundedYear",
      render(value: CompanyDto) {
        return (
          <div
            className="px-3 py-4 truncate text-silver-chalice flex flex-row items-center justify-center h-full bg-nero"
            onClick={() => openCompany(value)}
          >
            {value?.yearFounded || value?.year_founded}
          </div>
        );
      },
    },
  ];

  const saveList = () => {
    setShowCompanyListModal(true);
  };

  const openLists = () => {
    setShowCompanyListModal(true);
  };

  const openList = (id: string) => {
    setSelectedList(id);
    setShowCompanyListModal(false);
    setShowCompaniesListModal(true);
  };

  const renderUi = () => {
    return showTable ? (
      <div>
        <div className="flex flex-row items-center justify-start px-5 pt-5 pb-3 mb-3 border-b border-solid border-border gap-4">
          <button
            onClick={hideFilters}
            className="px-4 py-3 flex flex-row justify-center items-center text-white bg-mine-shaft rounded-2xl text-base font-bold"
          >
            <FaFilter />
            <p className="ml-2">Hide Filters</p>
          </button>

          <p>Total ({count})</p>
          <p>Net New (194.5K)</p>
          <p>Saved</p>
        </div>
        <div className="p-5 pt-2">
          <div className="flex flex-row mb-5 gap-2 items-center">
            <div>
              <label className="checkbox_block">
                <input
                  type="checkbox"
                  onChange={selectedAll}
                  checked={selected?.length === data?.length}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <button
              className={classNames(
                "px-4 py-3 flex flex-row justify-center items-center ml-4 text-white bg-mine-shaft rounded-2xl text-base font-bold w-28",
                {
                  "opacity-50": selected?.length === 0,
                }
              )}
              disabled={selected?.length === 0}
              onClick={saveList}
            >
              <FaPlus />
              <p className="ml-2">Save</p>
            </button>

            <button
              className="px-4 py-3 flex flex-row items-center justify-center text-white bg-mine-shaft rounded-2xl text-base font-bold w-28"
              onClick={openLists}
            >
              <RiListSettingsFill />
              <p className="ml-2">Lists</p>
            </button>

            {/* <button className="border border-solid border-gray-30 rounded p-2 flex flex-row items-center text-gray-80">
              <CiImport />
              <p className="ml-2">Export</p>
            </button> */}
          </div>

          <div className="w-screen max-w-full overflow-x-auto py-1">
            <Table
              className={classNames("w-full max-h-full companies-table pl-1", {
                "h-full": !data?.length,
              })}
              expandable={{}}
              columns={columns()}
              data={data}
              emptyText={() => {
                return (
                  <div className="flex flex-col justify-center items-center h-full gap-8">
                    <p className="text-gray-80">No result found</p>
                    <p className="text-gray-50">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                );
              }}
            />
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.floor(count / LIMIT)}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            breakClassName="p-1 m-1"
            marginPagesDisplayed={2}
            containerClassName="flex flex-row mt-5 justify-center"
            pageClassName="p-1 m-1 rounded border border-solid border-gray-40 text-white"
            pageLinkClassName="p-1 m-1"
            previousClassName="p-1 m-1"
            previousLinkClassName="p-1 m-1"
            nextClassName="p-1 m-1"
            nextLinkClassName="p-1 m-1"
            activeClassName="bg-gray-40 rounded"
          />
        </div>
      </div>
    ) : (
      <div className="p-5">
        <Screening applyFilters={applyFilters} />
      </div>
    );
  };

  return (
    <div className="flex-1 h-full bg-cod-gray min-h-screen">
      {loading ? (
        <div className="flex flex-row justify-center w-screen h-screen absolute z-10 bg-black-60">
          <SpinnerCircularFixed
            size={56}
            thickness={100}
            speed={100}
            color="#6750A4"
            secondaryColor="#E6E6EA"
          />
        </div>
      ) : null}
      {renderUi()}
      <Tooltip id="tooltip" className="max-w-[400px]" />

      {showCompanyModal && selectedCompany ? (
        <ViewCompany
          isOpen={showCompanyModal}
          company={selectedCompany}
          onClose={() => {
            setCompany(undefined);
            setShowCompanyModal(false);
          }}
        />
      ) : null}

      {showCompanyListModal ? (
        <CreateCompanyList
          isOpen={showCompanyListModal}
          companyIds={selected?.length ? selected : []}
          editMode={selected?.length > 0}
          showInput={selected?.length > 0}
          onClose={() => {
            setSelected([]);
            setShowCompanyListModal(false);
          }}
          openList={openList}
        />
      ) : null}

      {showCompaniesListModal && selectedList ? (
        <CompaniesList
          isOpen={showCompaniesListModal}
          listId={selectedList}
          onClose={() => {
            setSelectedList(undefined);
            setShowCompaniesListModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default Companies;

// corporate finance, mergers, debt advisory
