import Modal from "react-modal";

interface ConfirmModalI {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message: string;
}

const ConfirmModal = ({
  isOpen,
  onClose,
  onSubmit,
  message,
}: ConfirmModalI) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal-inside outline-none"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col p-5 h-full min-w-[180px]">
        <div className="flex flex-row justify-center">
          <p className="text-silver-chalice">{message}</p>
        </div>
        <div className="flex flex-row gap-2 mt-4 w-full justify-between">
          <button className="bg-transparent border-none text-white" onClick={onClose}>
            Close
          </button>
          <button className="bg-transparent border-none text-white" onClick={onSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
