import Api from "../axios/api";

export interface AccountDto {
  stage?: string;
  owner?: string;
}

export interface SocialDto {
  linkedinUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
}
export interface FundingDto {
  amount: string;
  date?: string;
  type?: string;
  investors: string;
  raisedAt?: string;
}

export interface CompanyDto {
  id: string;
  name: string;
  website?: string;
  rangeLinkedIn?: string;
  country: string;
  region?: string;
  city?: string;
  postalCode?: string;
  street?: string;
  address?: string;
  industry?: string;
  yearFounded?: number;
  year_founded?: number;
  employeesCount?: number;
  employees_count?: number;
  description?: string;
  ownershipType?: string;
  totalFunding?: string;
  ownership_type: string;
  investors?: string;
  fundingRoundCount?: number;
  keywords?: string;
  companyPhone?: string;
  technologies?: string;
  shortDescription?: string;
  annualRevenue?: string;
  annual_revenue?: string;
  short_description?: string;
  apolloId?: string;
  sicCoodes?: string;
  logo?: string;
  numberOfRetailLocations?: string;
  activities?: string[];
  account: AccountDto;
  social: SocialDto;
  funding: FundingDto;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetCompaniesResponse {
  data: {
    items?: CompanyDto[];
    count: number;
  };
}

export interface FiltersCompanies {
  industries?: string[];
  countries?: string[];
  keywords?: string[];
  from?: number;
  to?: number;
  fromRevenue?: number;
  toRevenue?: number;
  search?: string;
  searchAi?: string;
}

export interface PaginationQuery extends FiltersCompanies {
  search?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortDirection?: string;
}

export class CompaniesService {
  static getAll(params: PaginationQuery): Promise<GetCompaniesResponse> {
    return Api.get(`companies`, {
      params,
    });
  }

  static search(search: string): Promise<GetCompaniesResponse> {
    return Api.get(`companies/search`, {
      params: { search },
    });
  }
}
