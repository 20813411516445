import React, { FC } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Companies from "../pages/Companies";
import Login from "../pages/Login";
import AuthGuardedRoute from "../Guards/AuthRoute";
import Register from "../pages/Register";
import InvestmentBanker from "../pages/InvestmentBanker";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuardedRoute>
        <Home />
      </AuthGuardedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/search",
    element: (
      <AuthGuardedRoute>
        <Companies />
      </AuthGuardedRoute>
    ),
  },
  {
    path: "/investment-banker",
    element: (
      <AuthGuardedRoute>
        <InvestmentBanker />
      </AuthGuardedRoute>
    ),
  },
  {
    path: "/investment-banker/:id",
    element: (
      <AuthGuardedRoute>
        <InvestmentBanker />
      </AuthGuardedRoute>
    ),
  },
]);

const AppRouter: FC = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
