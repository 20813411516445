import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { CiCircleRemove } from "react-icons/ci";

import {
  CountriesService,
  ResponseCountry,
} from "../../services/countries.service";
import { IndustiresService } from "../../services/industries.service";
import { FiltersCompanies } from "../../services/companies.service";

interface ScreeningI {
  applyFilters: (filters: FiltersCompanies) => void;
}

const Screening = ({ applyFilters }: ScreeningI) => {
  const [countries, setCountries] = useState<ResponseCountry[]>([]);
  const [industries, setIndustries] = useState<ResponseCountry[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<ResponseCountry[]>(
    []
  );
  const [selectedIndustries, setSelectedIndustries] = useState<
    ResponseCountry[]
  >([]);

  const [fromNr, setFromNr] = useState<string>("");
  const [toNr, setToNr] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [fromRevenue, setFromRevenue] = useState<string>("");
  const [toRevenue, setToRevenue] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [searchAi, setSearchAi] = useState<string>("");

  const [skip] = useState<number>(0);

  const getCountries = useCallback(async () => {
    try {
      const { data } = await CountriesService.getAll({
        limit: 50,
        offset: skip,
        sortDirection: "ASC",
      });
      setCountries(data || []);
    } catch (e) {}
  }, [skip]);

  const getIndustries = useCallback(async () => {
    try {
      const { data } = await IndustiresService.getAll({
        limit: 100,
        offset: skip,
        sortDirection: "ASC",
      });
      setIndustries(data || []);
    } catch (e) {}
  }, [skip]);

  useEffect(() => {
    getCountries();
    getIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && keyword?.length) {
      const newKeywords = keywords.filter((r) => r !== keyword);
      setKeywords([...newKeywords, keyword]);
      setKeyword("");
    }
  };

  const removeKeyWord = (key: string) => {
    const newKeywords = keywords.filter((r) => r !== key);
    setKeywords(newKeywords);
  };

  const applyFilter = () => {
    applyFilters({
      countries: selectedCountries?.map((r) => r.value),
      industries: selectedIndustries?.map((r) => r.value),
      keywords,
      from: fromNr ? parseInt(fromNr) : undefined,
      to: toNr ? parseInt(toNr) : undefined,
      fromRevenue: fromRevenue ? parseInt(fromRevenue) : undefined,
      toRevenue: toRevenue ? parseInt(toRevenue) : undefined,
      search,
      searchAi,
    });
  };

  const isDisabled = useMemo(
    () =>
      !(
        selectedCountries?.length ||
        selectedIndustries?.length ||
        fromNr ||
        toNr ||
        fromRevenue ||
        toRevenue ||
        keywords?.length ||
        search?.length ||
        searchAi?.length
      ),
    [
      fromNr,
      fromRevenue,
      keywords?.length,
      search?.length,
      selectedCountries?.length,
      selectedIndustries?.length,
      toNr,
      toRevenue,
      searchAi,
    ]
  );

  return (
    <div className="flex-1 flex flex-row justify-center h-screen">
      <div className="flex flex-col max-w-[500px] w-full">
        <div className="flex flex-col p-4 rounded-2xl bg-nero">
          <p className="font-bold text-white text-base">Search with Text</p>
          <div className="flex flex-col p-2 mt-2 rounded-sm">
            <label htmlFor="country" className="text-silver-chalice">
              Type below
            </label>
            <div className="mt-2 flex flex-row justify-start items-center w-full">
              <div className="flex flex-row flex-wrap gap-2 w-full">
                <input
                  type="text"
                  value={searchAi}
                  placeholder="e.g. Provide me with 5 companies located in London"
                  className="border w-full border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                  onChange={(e) => setSearchAi(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-4 rounded-2xl bg-nero mt-2">
          <p className="font-bold text-white text-base">Search with Filters</p>
          <div className="flex flex-col p-2 mt-2 rounded-sm">
            <div className="mt-2">
              <label htmlFor="country" className="text-silver-chalice">
                Select Country
              </label>
              <Select
                id="country"
                className="mt-2 custom-select"
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                name="country"
                classNamePrefix="custom-select"
                options={countries}
                onChange={(data) =>
                  setSelectedCountries(data as ResponseCountry[])
                }
              />
            </div>
            <div className="mt-2">
              <label htmlFor="industry" className="text-silver-chalice">
                Select Industry
              </label>
              <Select
                id="industry"
                className="mt-2 max-w-[500px] custom-select"
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                name="industry"
                classNamePrefix="custom-select"
                options={industries}
                onChange={(data) =>
                  setSelectedIndustries(data as ResponseCountry[])
                }
              />
            </div>
            <div className="mt-2">
              <label htmlFor="country" className="text-silver-chalice">
                Company Name
              </label>
              <div className="mt-2 flex flex-row justify-start items-center">
                <div className="flex flex-row flex-wrap gap-2">
                  <input
                    type="text"
                    value={search}
                    className="border w-80 border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label htmlFor="country" className="text-silver-chalice">
                Set No of Staff
              </label>
              <div className="mt-2 flex flex-row justify-start items-center">
                <input
                  type="number"
                  min={0}
                  value={fromNr}
                  onChange={(e) => setFromNr(e.target.value)}
                  className="border w-32 border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                />
                <p className="ml-3 text-gray-80">to</p>
                <input
                  type="number"
                  min={0}
                  value={toNr}
                  onChange={(e) => setToNr(e.target.value)}
                  className="ml-3 w-32 border border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                />
              </div>
            </div>
            <div className="mt-2">
              <label htmlFor="country" className="text-silver-chalice">
                Set Revenue
              </label>
              <div className="mt-2 flex flex-row justify-start items-center">
                <input
                  type="number"
                  min={0}
                  value={fromRevenue}
                  onChange={(e) => setFromRevenue(e.target.value)}
                  className="border w-32 border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                />
                <p className="ml-3 text-gray-80">to</p>
                <input
                  type="number"
                  min={0}
                  value={toRevenue}
                  onChange={(e) => setToRevenue(e.target.value)}
                  className="ml-3 w-32 border border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                />
              </div>
            </div>
            <div className="mt-2">
              <label htmlFor="country" className="text-silver-chalice">
                Set Key words
              </label>
              <div className="mt-2 flex flex-row justify-start items-center">
                <div className="flex flex-row flex-wrap gap-2">
                  {keywords.map((k) => {
                    return (
                      <div
                        key={k}
                        className="border border-solid border-silver-chalice bg-cod-gray rounded py-1 px-2 flex flex-row items-center"
                      >
                        {k}
                        <button
                          onClick={() => removeKeyWord(k)}
                          className="ml-3"
                        >
                          <CiCircleRemove />
                        </button>
                      </div>
                    );
                  })}
                  <input
                    type="text"
                    value={keyword}
                    className="border w-40 border-solid border-silver-chalice bg-cod-gray rounded px-2 py-1 outline-none"
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-end">
          <button
            onClick={applyFilter}
            className={`rounded p-2 bg-hero-pattern text-white w-28 flex flex-row justify-center ${
              isDisabled ? "opacity-50" : ""
            }`}
            disabled={isDisabled}
          >
            Apply filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default Screening;
