import { ToastContainer } from "react-toastify";

import GlobalProvider from "./context/provider";
import AppRouter from "./routes";

function App() {
  return (
    <GlobalProvider>
      <AppRouter />
      <ToastContainer />
    </GlobalProvider>
  );
}

export default App;
