import Api from "../axios/api";
export interface ResponseCountry {
  value: string;
  label: string;
}

export interface GetCountriesResponse {
  data: ResponseCountry[];
}

export interface PaginationQuery {
  limit?: number;
  offset?: number;
  sortDirection?: string;
}

export class CountriesService {
  static getAll(params: PaginationQuery): Promise<GetCountriesResponse> {
    return Api.get(`countries`, {
      params,
    });
  }
}
