import Api from "../axios/api";
import { AccountDto, CompanyDto } from "./companies.service";

export interface CompanyListDto {
  id: string;
  name: string;
  users?: AccountDto[];
  companies?: CompanyDto[];
  createdAt: Date;
  updatedAt: Date;
}

export interface GetCompaniesResponse {
  data: {
    items?: CompanyListDto[];
    count: number;
  };
}

export class CompaniesListService {
  static getAll(): Promise<{ data: CompanyListDto[] }> {
    return Api.get(`company-lists`);
  }

  static getOne(listId: string): Promise<{ data: CompanyListDto }> {
    return Api.get(`company-lists/${listId}`);
  }

  static getCompanies(listId: string): Promise<{ data: CompanyDto[] }> {
    return Api.get(`company-lists/${listId}/companies`);
  }

  static create(name: string): Promise<{ data: CompanyListDto }> {
    return Api.post(`company-lists`, { name });
  }

  static addCompanies(
    listId: string,
    companyIds: string[]
  ): Promise<{ data: CompanyListDto }> {
    return Api.post(`company-lists/${listId}/add-companies`, { companyIds });
  }
}
