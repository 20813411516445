import { FC, useContext } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { AuthRequest, AuthService } from "../../services/auth.services";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context";

const Login: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get("redirectUrl");
  const { setUser } = useContext(AuthContext);

  const login = async (values: AuthRequest) => {
    try {
      const { data } = await AuthService.login(values);
      localStorage.setItem("brand_access_token", data.accessToken);
      localStorage.setItem("brand_refresh_token", data.refreshToken);
      const resp = await AuthService.getMe();
      setUser(resp.data);
      if (redirectUrl?.length) {
        navigate(`/${redirectUrl}`);
      } else {
        navigate("/");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          `Request failed with status code ${error?.response?.status}`,
        { theme: "dark" }
      );
    } finally {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      login(values);
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Emial is required"),
      password: Yup.string().required("Password is required"),
    }),
  });

  return (
    <div className="flex-1 h-screen bg-cod-gray">
      <div className="flex-1 h-full flex flex-row justify-center items-center">
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="flex flex-col"
        >
          <div className="flex flex-col">
            <label htmlFor="email" className="text-silver-chalice">
              Email
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="text"
              name="email"
              placeholder="example@google.com"
              value={formik?.values.email}
              autoComplete="false"
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white-100"
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.email.toString()}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col mt-4">
            <label htmlFor="password" className="text-silver-chalice">
              Password
              <span className="text-brick-red ml-1">*</span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="example"
              value={formik?.values.password}
              onChange={formik?.handleChange}
              className="border min-w-[300px] bg-cod-gray border-solid border-silver-chalice rounded px-2 py-1 outline-none mt-2 text-white"
            />
            {formik.touched.password && formik.errors.password ? (
              <span className="text-xs text-brick-red mt-1">
                {formik.errors.password.toString()}
              </span>
            ) : null}
          </div>
          <button
            type="submit"
            className={`text-white text-base font-bold rounded-2xl p-2 mt-5 bg-hero-pattern`}
          >
            Log in
          </button>
          <button
            className="mt-4 text-silver-chalice text-sm leading-6 font-normal"
            onClick={() =>
              navigate(
                redirectUrl
                  ? `/register?redirectUrl=${redirectUrl}`
                  : "/register"
              )
            }
          >
            or Register
          </button>
        </form>
      </div>
      <Tooltip id="tooltip" className="max-w-[400px]" />
    </div>
  );
};

export default Login;
