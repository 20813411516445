import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AuthContext } from "../context/context";
import { useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

interface GuardProps {
  children: ReactElement<any, any>;
}

const AuthGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { logout, user } = useContext(AuthContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get("redirect_url");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const exist = localStorage.getItem("brand_access_token");
    if (!exist) {
      if (redirectUrl) {
        logout(redirectUrl);
      } else {
        logout();
      }
    }
  }, [logout, redirectUrl]);

  const initials = useMemo(() => {
    let initials = user?.email?.charAt(0);
    if (user?.firstName?.length || user?.lastName?.length) {
      initials =
        (user?.firstName?.charAt(0) || "") + (user?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [user?.email, user?.firstName, user?.lastName]);

  return (
    <>
      {initials ? (
        <div className="absolute right-3 top-3 text-white">
          <div
            className="flex flex-row justify-center items-center h-9 w-9 bg-dull-lavender rounded-full cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {initials}
          </div>
        </div>
      ) : null}
      {isOpen && initials ? (
        <div className="absolute shadow flex flex-col right-3 top-16 bg-cod-gray rounded-md p-2 border border-solid border-gray">
          <div
            className="text-white cursor-pointer flex flex-row items-center"
            onClick={() => logout()}
          >
            <FiLogOut /> <p className="text-white m-0 ml-2">Log out</p>
          </div>
        </div>
      ) : null}
      <div onClick={() => (isOpen ? setIsOpen(false) : null)}>{children}</div>
    </>
  );
};

export default AuthGuardedRoute;
