import {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SpinnerCircularFixed } from "spinners-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InfiniteScroll from "react-infinite-scroller";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { FiUpload } from "react-icons/fi";

import {
  DocumentDto,
  DocumentsService,
  QuestionDto,
} from "../../services/documents.service";
import { toast } from "react-toastify";
import { FaRegCopy, FaEdit } from "react-icons/fa";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { AuthContext } from "../../context/context";
import ConfirmModal from "../../components/ConfirmModal";
import EditModal from "../../components/EditModal";
import { AuthService, User } from "../../services/auth.services";

const LIMIT = 10;
const InvestmentBanker: FC = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string | undefined>();
  const [results, setResults] = useState<QuestionDto[]>([]);
  const [offsetResults, setOffsetResults] = useState<number>(0);
  const [countRelults, setCountRelults] = useState<number>(0);
  const [documents, setDocuments] = useState<DocumentDto[]>([]);
  const [offsetDocuments, setOffsetDocuments] = useState<number>(0);
  const [countDocuments, setCountDocuments] = useState<number>(0);
  const [acctionId, setAcctionId] = useState<string | undefined>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>();
  const scrollRef = useRef(null) as any;
  const [userLocal, setLocalUser] = useState<User>();
  const { user, setUser } = useContext(AuthContext);

  const newChat = () => {
    setUuid(undefined);
    setResults([]);
    setCountRelults(0);
  };

  const goTo = (id: string) => {
    setLoading(true);
    newChat();
    window.location.replace(`/investment-banker/${id}`);
  };

  const handleFileChange = async (event: any) => {
    const file: File = event.target.files[0];
    if (!file) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await DocumentsService.upload(file);
      setDocuments([data, ...documents]);
      setUuid(data.uuid);
      goTo(data.id);
    } catch (e: any) {
      console.log(e);
      toast(
        e?.response?.data?.message ||
          "The file size exceeds your 20MB per document limit.",
        {
          type: "error",
          theme: "dark",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollTo({
        top: document.body.scrollHeight * 200,
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  };

  const changeResults = async (question: string) => {
    try {
      if (!uuid) {
        return;
      }
      setLoading(true);
      const { data } = await DocumentsService.question(uuid, question);
      setResults([...results, data]);
      setCountRelults(countRelults + 1);
      scrollToBottom();
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      formik.resetForm();
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    onSubmit: (values) => {
      changeResults(values.question);
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Question is required"),
    }),
  });

  const copyText = (text: string) => {
    try {
      navigator?.clipboard?.writeText(text);
      toast("Copied successfully", {
        type: "success",
        theme: "dark",
      });
    } catch (e) {}
  };

  const getDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await DocumentsService.getList({
        limit: LIMIT,
        offset: offsetDocuments,
      });
      setDocuments(data.items);
      setCountDocuments(data.count);
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };

  const getAnswers = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      setLoading(true);
      const { data } = await DocumentsService.getQuestionsList(id, {
        limit: LIMIT,
        offset: offsetResults,
      });
      setResults([...data.items, ...results]);
      setCountRelults(data.count);
      scrollToBottom();
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  }, [id, offsetResults, results]);

  const getUser = useCallback(async () => {
    try {
      if (user && user.id) {
        setLocalUser(user);
        return;
      }
      const { data } = await AuthService.getMe();
      setLocalUser(data);
      setUser(data);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getDocument = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await DocumentsService.getById(id);
      setUuid(data.uuid);
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initials = useMemo(() => {
    let initials = userLocal?.email?.charAt(0);
    if (userLocal?.firstName?.length || userLocal?.lastName?.length) {
      initials =
        (userLocal?.firstName?.charAt(0) || "") +
        (userLocal?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [userLocal?.email, userLocal?.firstName, userLocal?.lastName]);

  const loadMoreResults = (page: number) => {
    if (page < countRelults / LIMIT) {
      setOffsetResults(page * LIMIT);
    }
  };

  useEffect(() => {
    getAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetResults]);

  useEffect(() => {
    if (id) {
      getDocument();
      getAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadMoreDocumnets = (page: number) => {
    if (page < countRelults / LIMIT) {
      setOffsetDocuments(page * LIMIT);
    }
  };

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetDocuments]);

  const openActions = (docId: string, left: number, top: number) => {
    setLeft(left);
    setTop(top);
    setAcctionId(docId);
    setOpen(true);
  };

  const checkEditDoc = () => {
    const forEditDoc = documents.find((d) => d.id === acctionId);
    setLeft(0);
    setTop(0);
    setOpen(false);
    setConfirmEdit(true);
    setName(forEditDoc?.name);
  };

  const editDoc = useCallback(async () => {
    try {
      if (!acctionId) {
        return;
      }
      const { data } = await DocumentsService.update(acctionId, name);
      const newDocuments = documents.map((d) => {
        if (d.id === acctionId) {
          return { ...d, ...data };
        }
        return d;
      });
      setDocuments(newDocuments);
      setConfirmEdit(false);
      setAcctionId(undefined);
      setName(undefined);
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  }, [acctionId, documents, name]);

  const checkDeleteDoc = () => {
    setLeft(0);
    setTop(0);
    setOpen(false);
    setConfirmDelete(true);
  };

  const deleteDoc = useCallback(async () => {
    try {
      if (!acctionId) {
        return;
      }
      await DocumentsService.delete(acctionId);
      const deletedDoc = documents.find((d) => d.id === acctionId);
      const newDocuments = documents.filter((d) => d.id !== acctionId);
      setDocuments(newDocuments);
      setConfirmDelete(false);
      setAcctionId(undefined);
      if (uuid === deletedDoc?.uuid) {
        goTo(newDocuments?.at(0)?.id!);
      }
    } catch (e: any) {
      toast(e?.response?.data?.message || e?.message, {
        type: "error",
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acctionId, documents, uuid]);

  const closeModal = (event?: SyntheticEvent) => {
    const target = event?.target as Element;
    const hasParentWithId = target.closest("#open_actions");
    if (!hasParentWithId) {
      setOpen(false);
      setLeft(0);
      setTop(0);
      setAcctionId(undefined);
      setConfirmDelete(false);
      setConfirmEdit(false);
      setName(undefined);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-mine-shaft h-screen">
      {loading ? (
        <div className="flex flex-row justify-center w-screen h-screen absolute z-10 bg-black-60">
          <SpinnerCircularFixed
            size={56}
            thickness={100}
            speed={100}
            color="#6750A4"
            secondaryColor="#E6E6EA"
          />
        </div>
      ) : null}
      <div className="flex flex-row h-screen" onClick={(e) => closeModal(e)}>
        <div className="flex flex-col bg-cod-gray max-w-xs p-2 w-[320px] min-w-[320px]">
          <div key="braind" className="flex flex-row justify-between p-3 mb-8">
            <div className="flex flex-row items-center">
              <img
                src="/logo-without.png"
                alt="logo"
                className="w-5 h-5 rounded-xl"
              />
              <p className="text-white ml-3">Braind</p>
            </div>
            <button className="bg-transparent border-none" onClick={newChat}>
              <FaEdit fill="#fff" />
            </button>
          </div>
          <InfiniteScroll
            id="documents"
            pageStart={offsetDocuments / LIMIT}
            loadMore={loadMoreDocumnets}
            hasMore={
              !!(
                documents?.length &&
                countDocuments &&
                documents?.length < countDocuments
              )
            }
          >
            {documents?.map((d) => {
              return (
                <div
                  key={d.id}
                  className={classNames(
                    "hover:bg-cod-gray p-3 rounded-md flex flex-row justify-between w-full",
                    {
                      "bg-cod-gray": d.uuid === uuid,
                    }
                  )}
                >
                  <p
                    className="text-white truncate max-w-[240px] cursor-pointer w-full"
                    onClick={() => goTo(d.id)}
                  >
                    {d.name}
                  </p>
                  <button
                    className="bg-transparent border-none"
                    onClick={(e) => {
                      openActions(d.id, e.clientX, e.clientY);
                    }}
                    id="open_actions"
                  >
                    <MdOutlineMoreHoriz fill="#fff" />
                  </button>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
        <div className="flex flex-col mx-20 pt-2 justify-end w-full">
          <div
            className="flex flex-col overflow-y-auto max-h-[calc(100vh-165px)] p-4"
            ref={scrollRef}
          >
            <InfiniteScroll
              id="results"
              pageStart={offsetResults / LIMIT}
              loadMore={loadMoreResults}
              isReverse={true}
              hasMore={
                !!(
                  results?.length &&
                  countRelults &&
                  results?.length < countRelults
                )
              }
            >
              {results?.map((result, index) => {
                return (
                  <div key={index} className="mt-4 pb-2 flex flex-row">
                    <div className="mr-1 text-gray-50"></div>
                    <div>
                      <div className="flex flex-row justify-start">
                        <div className="w-6 h-6 rounded-xl text-xs min-w-[24px] bg-mine-shaft flex flex-row justify-center items-center text-silver-chalice">
                          {initials}
                        </div>
                        <div className="flex flex-col ml-4">
                          <div className="flex flex-row text-white text-xl font-bold">
                            <p>You</p>
                            <button
                              className="flex outline-none bg-transparent p-0 m-0 ml-2"
                              onClick={() => copyText(result.question)}
                            >
                              <FaRegCopy className="mt-1" />
                            </button>
                          </div>
                          <p className="text-silver-chalice text-sm leading-6 font-normal mt-1">
                            {result.question}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-start mt-4">
                        <img
                          src="/logo-without.png"
                          alt="logo"
                          className="w-6 h-6 rounded-xl min-w-[24px]"
                        />
                        <div className="flex flex-col ml-4">
                          <div className="flex flex-row text-white text-xl font-bold">
                            <p>Braind</p>
                            <button
                              className="flex outline-none bg-transparent p-0 m-0 ml-2"
                              onClick={() => copyText(result.answer)}
                            >
                              <FaRegCopy className="mt-1" />
                            </button>
                          </div>
                          <p className="text-silver-chalice text-sm leading-6 font-normal mt-1">
                            {result.answer}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>

          {!loading ? (
            <div
              className={classNames(
                "flex flex-row justify-center items-center",
                {
                  "h-full": !uuid,
                }
              )}
            >
              {uuid ? (
                <div className="mb-5 w-full">
                  {!results?.length ? (
                    <div>
                      <div className="flex flex-col justify-center items-center h-[calc(100vh-200px)]">
                        <img
                          src="/logo-without.png"
                          alt="logo"
                          className="w-10 h-10 rounded-3xl"
                        />
                        <p className="text-white mt-2">
                          How can I help you today?
                        </p>
                      </div>
                      <div>
                        <div className="flex flex-row w-full gap-2 justify-between">
                          <div
                            className="flex flex-row flex-wrap border border-solid rounded-md border-white w-1/2 p-2 cursor-pointer"
                            onClick={() =>
                              changeResults("What is the document about?")
                            }
                          >
                            <p className="text-silver-chalice">
                              What is the document about?
                            </p>
                          </div>
                          <div
                            className="flex flex-row flex-wrap border border-solid rounded-md border-white w-1/2 p-2 cursor-pointer"
                            onClick={() =>
                              changeResults("Summarise the key points.")
                            }
                          >
                            <p className="text-silver-chalice">
                              Summarise the key points.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-row bg-transparent items-end justify-center w-full"
                  >
                    <textarea
                      name="question"
                      placeholder="Ask any question about the document"
                      value={formik?.values.question}
                      onChange={formik?.handleChange}
                      className="border min-w-[300px] w-full mt-4 max-w-lg text-white border-solid border-silver-chalice rounded bg-cod-gray px-2 py-1 outline-none max-h-20"
                    />
                    {formik.touched.question && formik.errors.question ? (
                      <span className="text-xs text-red-600 mt-1">
                        {formik.errors.question.toString()}
                      </span>
                    ) : null}
                    <button
                      type="submit"
                      className={`flex h-full text-white rounded p-2 ml-5 bg-hero-pattern w-28 flex-row justify-center`}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center p-8">
                  <div className="flex max-w-sm flex-row justify-center text-center mb-5 text-silver-chalice">
                    <img
                      src="/logo-without.png"
                      alt="logo"
                      className="w-10 h-10 rounded-3xl"
                    />
                  </div>
                  <label
                    htmlFor="fileInput"
                    className="rounded-xl p-2 bg-hero-pattern cursor-pointer flex flex-row justify-center items-center"
                  >
                    <FiUpload stroke="#fff" className="mt-[-2px]" />
                    <p className="text-white m-0 ml-2">Upload document</p>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {isOpen ? (
        <div
          className="flex flex-col absolute p-2 bg-nero rounded-md shadow-name"
          style={{ top: `${top + 5}px`, left: `${left + 5}px` }}
        >
          <div
            onClick={checkEditDoc}
            className="text-white py-1 px-2 cursor-pointer rounded hover:bg-cod-gray"
          >
            Edit
          </div>
          <div
            onClick={checkDeleteDoc}
            className="text-white py-1 px-2 cursor-pointer rounded hover:bg-cod-gray"
          >
            Delete
          </div>
        </div>
      ) : null}
      {confirmDelete ? (
        <ConfirmModal
          isOpen={confirmDelete}
          onClose={() => {
            setAcctionId(undefined);
            setConfirmDelete(false);
          }}
          message="Are you sure?"
          onSubmit={deleteDoc}
        />
      ) : null}
      {confirmEdit ? (
        <EditModal
          isOpen={confirmEdit}
          onClose={() => {
            setAcctionId(undefined);
            setConfirmEdit(false);
            setName(undefined);
          }}
          name={name!}
          onSubmit={editDoc}
          setName={setName}
        />
      ) : null}
      {!userLocal?.paid ? (
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="h-full w-full bg-black-60 z-0 relative" />
          <div className="h-full w-full flex justify-center items-center z-10 absolute top-0 left-0">
            <stripe-buy-button
              client-reference-id={userLocal?.id}
              buy-button-id={process.env.REACT_APP_STRIPE_BUTTON_ID}
              publishable-key={process.env.REACT_APP_STRIPE_KEY}
            ></stripe-buy-button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvestmentBanker;

// corporate finance, mergers, debt advisory
