import { createContext } from "react";

import { AuthContextProps } from "./context.types";

export const AuthContext = createContext<AuthContextProps>({
  loading: false,
  setLoading: () => null,
  user: undefined,
  setUser: () => null,
  logout: () => null,
});
