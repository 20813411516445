import { AxiosInstance, AxiosResponse } from 'axios';

let isRefreshing = false;
let refreshPromise: Promise<AxiosResponse<any, any>> | null = null;
let requestsQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
  originalRequest: any;
}[] = [];

function processQueue(api: AxiosInstance, error: null) {
  requestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (prom.originalRequest) {
      prom.resolve(api(prom.originalRequest));
    }
  });

  requestsQueue = [];
}

export const onReject = (api: AxiosInstance) => {
  return async (error: any) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        originalRequest._retry = true;

        try {
          isRefreshing = true;

          const refresh = localStorage.getItem('brand_refresh_token');

          if (!refreshPromise) {
            refreshPromise = api
              .post('/auth/refresh-token', { token: refresh })
              .then((response) => {
                const { accessToken, refreshToken } = response.data;
                localStorage.setItem('brand_access_token', accessToken);
                localStorage.setItem('brand_refresh_token', refreshToken);

                // Retry all requests in the queue with the new token
                processQueue(api, null);

                return originalRequest;
              })
              .catch((error) => {
                localStorage.removeItem('brand_access_token');
                localStorage.removeItem('brand_refresh_token');

                window?.location?.replace('/');
                processQueue(api, error);
                return Promise.reject(error);
              })
              .finally(() => {
                isRefreshing = false;
                refreshPromise = null;
              });
          }

          return new Promise((resolve, reject) => {
            requestsQueue.push({ resolve, reject, originalRequest });
          });
        } catch (error) {
          localStorage.removeItem('brand_access_token');
          localStorage.removeItem('brand_refresh_token');
          localStorage.removeItem('role');
          localStorage.removeItem('last_active_tab');
          console.warn('LOGOUT');

          window?.location?.replace('/');
          return Promise.reject(error);
        }
      } else {
        return new Promise((resolve, reject) => {
          requestsQueue.push({ resolve, reject, originalRequest });
        });
      }
    }

    return Promise.reject(error);
  };
};
