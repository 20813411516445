import Api from "../axios/api";
import { AccountDto } from "./companies.service";

export interface QuestionDto {
  id: string;
  question: string;
  answer: string;
  document?: DocumentDto;
}
export interface DocumentDto {
  id: string;
  fileName: string;
  name: string;
  uuid: string;
  filePath: string;
  user: AccountDto;
  questions: QuestionDto[];
  createdAt: Date;
  updatedAt: Date;
}
export interface ResponseDocumentListDTO {
  items: DocumentDto[];
  count: number;
}
export interface ResponseQuestionListDTO {
  items: QuestionDto[];
  count: number;
}
export class DocumentsService {
  static upload(file: File): Promise<{ data: DocumentDto }> {
    const formData = new FormData();
    formData.append("file", file);
    return Api.post(`documents/upload`, formData);
  }

  static question(
    uuid: string,
    question: string
  ): Promise<{ data: QuestionDto }> {
    return Api.post("documents", {
      question,
      uuid,
    });
  }

  static getList(params?: {
    limit?: number;
    offset?: number;
  }): Promise<{ data: ResponseDocumentListDTO }> {
    return Api.get("documents", {
      params,
    });
  }

  static getQuestionsList(
    id: string,
    params?: {
      limit?: number;
      offset?: number;
    }
  ): Promise<{ data: ResponseQuestionListDTO }> {
    return Api.get(`documents/${id}/questions`, {
      params,
    });
  }

  static delete(id: string): Promise<{ data: { message: string } }> {
    return Api.delete(`documents/${id}`);
  }

  static update(id: string, name?: string): Promise<{ data: DocumentDto }> {
    return Api.patch(`documents/${id}`, { name });
  }

  static getById(id: string): Promise<{ data: DocumentDto }> {
    return Api.get(`documents/${id}`);
  }
}
