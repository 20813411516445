import Modal from "react-modal";
import { FaWindowClose } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import {
  CompaniesListService,
  CompanyListDto,
} from "../../services/companies-list.service";
import classNames from "classnames";
import { CompanyDto } from "../../services/companies.service";

interface CompaniesListI {
  isOpen: boolean;
  onClose: () => void;
  listId: string;
}

const CompaniesList = ({ isOpen, onClose, listId }: CompaniesListI) => {
  const [list, setList] = useState<CompanyListDto | undefined>();
  const [companies, setCompanies] = useState<CompanyDto[]>([]);

  const getList = useCallback(async () => {
    try {
      const { data } = await CompaniesListService.getOne(listId);
      const resp = await CompaniesListService.getCompanies(listId);
      setList(data);
      setCompanies(resp.data);
    } catch (e) {
      console.log(e);
    }
  }, [listId]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal-inside outline-none min-w-[400px]"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col p-5 h-full">
        <div className="flex flex-row justify-end mb-3">
          <button onClick={onClose}>
            <FaWindowClose  fill="#ffffff" stroke="#0A0A0A" />
          </button>
        </div>

        <div className="flex flex-col max-w-3xl overflow-y-auto h-full">
          <div className="mt-2 text-white font-bold">List name: {list?.name}</div>
          <div className={classNames("flex flex-col pb-3")}>
            {companies?.length ? <p className="m-0 mb-1 text-white">Companies</p> : null}
            {companies?.length ? (
              <div className="flex flex-col">
                {companies.map((c, index) => {
                  return (
                    <div key={c.id} className="flex flex-row mb-1">
                      <p className="m-0 text-gray-50">{index + 1}.</p>
                      <p className="m-0 ml-2 text-silver-chalice">{c.name}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-row justify-center text-silver-chalice mt-3">
                You don't have compainies in this list
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompaniesList;
