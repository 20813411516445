import Modal from "react-modal";
import { CompanyDto } from "../../services/companies.service";
import { FaWindowClose } from "react-icons/fa";

interface ViewCompanyI {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyDto;
}

const ViewCompany = ({ isOpen, onClose, company }: ViewCompanyI) => {
  const address = company?.address
    ? company?.address
    : `${company?.city}, ${company?.country}`;
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal-inside outline-none h-full"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col p-5 h-full">
        <div className="flex flex-row justify-end mb-3">
          <button onClick={onClose}>
            <FaWindowClose fill="#ffffff" stroke="#0A0A0A" />
          </button>
        </div>
        <div className="flex flex-col max-w-3xl overflow-y-auto h-full">
          <div className="flex flex-row">
            <p className="w-36 min-w-[144px] text-silver-chalice">
              Company Name:
            </p>
            <p className="text-gray-60 pl-2">{company.name}</p>
          </div>
          {address ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Location:
              </p>
              <p className="text-gray-60 pl-2">{address}</p>
            </div>
          ) : null}
          {company.employeesCount || company.employees_count ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Employees:
              </p>
              <p className="text-gray-60 pl-2">
                {company.employeesCount || company.employees_count}
              </p>
            </div>
          ) : null}
          {company.industry ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Industry:
              </p>
              <p className="text-gray-60 pl-2">{company.industry}</p>
            </div>
          ) : null}
          {company.annualRevenue || company.annual_revenue ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">Revenue:</p>
              <p className="text-gray-60 pl-2">
                {company.annualRevenue || company.annual_revenue}
              </p>
            </div>
          ) : null}
          {company.ownershipType || company.ownership_type ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Ownership Type:
              </p>
              <p className="text-gray-60 pl-2">
                {company.ownershipType || company.ownership_type}
              </p>
            </div>
          ) : null}
          {company.yearFounded || company.year_founded ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Founded Year:
              </p>
              <p className="text-gray-60 pl-2">
                {company.yearFounded || company.year_founded}
              </p>
            </div>
          ) : null}
          {company.shortDescription || company.short_description ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Short description:
              </p>
              <p className="text-gray-60 pl-2">
                {company.shortDescription || company.short_description}
              </p>
            </div>
          ) : null}
          {company.description ? (
            <div className="flex flex-row mt-2">
              <p className="w-36 min-w-[144px] text-silver-chalice">
                Description:
              </p>
              <p className="text-gray-60 pl-2">{company.description}</p>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ViewCompany;
